<template>
  <div>
    <HeaderPanel
      title="Inventory"
      :filter="filter"
      @search="handleSearch"
      placeholder="Search Product Name"
      @sidebar="sidebarFilter()"
      :hasDropdown="false"
      :hasSearch="true"
    />
    <div class="mt-3">
      <div class="bg-white">
        <b-tabs v-model="tabIndex" @change="handleTabs">
          <b-tab title="Inventory" class="p-3">
            <div class="">
              <table id="inventory">
                <thead>
                  <tr>
                    <th rowspan="2">
                      <div class="content-center cursor-pointer">Image</div>
                    </th>
                    <th rowspan="2">
                      <div
                        class="content-center cursor-pointer"
                        style="width: 200px"
                        @click="sortColumn(1)"
                      >
                        Product Name
                        <span class="sort-icon">
                          <font-awesome-icon
                            size="sm"
                            icon="caret-up"
                            :class="[
                              filter.SortColumnId == 1 &&
                              filter.SortType == false
                                ? 'opacity-2'
                                : '',
                              'color-primary ml-2',
                            ]"
                          />
                          <font-awesome-icon
                            size="sm"
                            icon="caret-down"
                            :class="[
                              filter.SortColumnId == 1 &&
                              filter.SortType == true
                                ? 'opacity-2'
                                : '',
                              'color-primary ml-2',
                            ]"
                          />
                        </span>
                      </div>
                    </th>
                    <th rowspan="2">
                      <div
                        class="content-center cursor-pointer"
                        @click="sortColumn(2)"
                      >
                        Available Stock
                        <span class="sort-icon">
                          <font-awesome-icon
                            size="sm"
                            icon="caret-up"
                            :class="[
                              filter.SortColumnId == 2 &&
                              filter.SortType == false
                                ? 'opacity-2'
                                : '',
                              'color-primary  ml-2',
                            ]"
                          />
                          <font-awesome-icon
                            size="sm"
                            icon="caret-down"
                            :class="[
                              filter.SortColumnId == 2 &&
                              filter.SortType == true
                                ? 'opacity-2'
                                : '',
                              'color-primary ml-2',
                            ]"
                          />
                        </span>
                      </div>
                    </th>

                    <th
                      :colspan="
                        list.product_branch.length == 0
                          ? 1
                          : list.product_branch.length
                      "
                      v-for="list of marketList"
                      :key="list.sale_channel_name"
                    >
                      {{ list.sale_channel_name }}
                    </th>
                  </tr>
                  <tr>
                    <template v-for="(list, a) of marketList">
                      <template v-if="list.product_branch.length > 0">
                        <template v-for="(branch, n) of list.product_branch">
                          <th
                            :key="list.sale_channel_name + a + n"
                            :style="{
                              width: `${
                                marketList.length == 1 ? '100%' : 'unset'
                              }`,
                            }"
                          >
                            <span>
                              {{ branch.branch_name }}
                            </span>
                          </th>
                        </template>
                      </template>
                      <template v-else>
                        <th
                          :key="'temp-row-' + a"
                          :style="{
                            width: `${
                              marketList.length == 1 ? '100%' : 'unset'
                            }`,
                          }"
                        >
                          <span> - </span>
                        </th>
                      </template>
                    </template>
                  </tr>
                </thead>
                <template v-if="!isInventoryLoad">
                  <tbody v-if="!noData">
                    <tr v-for="cell of data" :key="cell.product_id">
                      <td class="text-center background:white">
                        <b-img
                          class="image-box"
                          :src="cell.image || default_image"
                          @error="handleImageSrc"
                        />
                      </td>
                      <td>
                        <div style="width: 200px">{{ cell.product_name }}</div>
                      </td>
                      <td class="text-center">{{ cell.current_stock }}</td>
                      <template v-for="(list, m) of cell.product_marketplace">
                        <template v-if="list.product_branch.length > 0">
                          <td
                            class="text-center"
                            v-for="(branch, f) of list.product_branch"
                            :key="
                              cell.product_id +
                              '-' +
                              list.sale_channel_name +
                              m +
                              f
                            "
                          >
                            {{ branch.current_stock }}
                          </td>
                        </template>
                        <template v-else>
                          <td class="text-center" :key="'temp-cell-' + m">0</td>
                        </template>
                      </template>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="18">
                        <div class="text-center text-black my-2">
                          <strong class="ml-2">No data</strong>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </template>
                <template v-else>
                  <tbody>
                    <tr>
                      <td colspan="18">
                        <div class="text-center text-black my-2">
                          <b-spinner class="align-middle"></b-spinner>
                          <strong class="ml-2">Loading...</strong>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </table>

              <!-- {{ data }} -->
            </div>
            <Pagination
              class="mt-3"
              @handleChangeTake="handleChangeTake"
              @pagination="pagination"
              :filter="filter"
              :rows="rows"
            />
          </b-tab>
          <b-tab title="SKU Velocity" class="p-3">
            <div class="w-100 overflow-x-scroll">
              <table id="sku-table">
                <thead>
                  <tr>
                    <th rowspan="2" style="width: 44%">Name</th>
                    <th colspan="2" style="width: 20%">
                      {{
                        $moment(new Date())
                          .subtract(1, "week")
                          .format("DD MMM YYYY")
                      }}
                      -
                      {{ $moment(new Date()).format("DD MMM YYYY") }}
                    </th>
                    <th colspan="2" style="width: 20%">
                      {{
                        $moment(new Date())
                          .subtract(30, "day")
                          .format("DD MMM YYYY")
                      }}
                      -
                      {{ $moment(new Date()).format("DD MMM YYYY") }}
                    </th>
                    <th colspan="2" style="width: 20%">
                      {{
                        $moment(new Date())
                          .subtract(60, "day")
                          .format("DD MMM YYYY")
                      }}
                      -
                      {{ $moment(new Date()).format("DD MMM YYYY") }}
                    </th>
                  </tr>
                  <tr>
                    <th>
                      <div
                        class="content-center cursor-pointer"
                        @click="sortSKUColumn(1)"
                      >
                        Daily Avg Sold (7D)
                        <span class="sort-icon">
                          <font-awesome-icon
                            size="sm"
                            icon="caret-up"
                            :class="[
                              filterSKU.SortColumnId == 1 &&
                              filterSKU.SortType == false
                                ? 'opacity-2'
                                : '',
                              'color-primary  ml-2',
                            ]"
                          />
                          <font-awesome-icon
                            size="sm"
                            icon="caret-down"
                            :class="[
                              filterSKU.SortColumnId == 1 &&
                              filterSKU.SortType == true
                                ? 'opacity-2'
                                : '',
                              'color-primary ml-2',
                            ]"
                          />
                        </span>
                      </div>
                    </th>
                    <th>
                      <div
                        class="content-center cursor-pointer"
                        @click="sortSKUColumn(2)"
                      >
                        Day Left
                        <span class="sort-icon">
                          <font-awesome-icon
                            size="sm"
                            icon="caret-up"
                            :class="[
                              filterSKU.SortColumnId == 2 &&
                              filterSKU.SortType == false
                                ? 'opacity-2'
                                : '',
                              'color-primary  ml-2',
                            ]"
                          />
                          <font-awesome-icon
                            size="sm"
                            icon="caret-down"
                            :class="[
                              filterSKU.SortColumnId == 2 &&
                              filterSKU.SortType == true
                                ? 'opacity-2'
                                : '',
                              'color-primary ml-2',
                            ]"
                          />
                        </span>
                      </div>
                    </th>
                    <th>
                      <div
                        class="content-center cursor-pointer"
                        @click="sortSKUColumn(3)"
                      >
                        Daily Avg Sold (30D)
                        <span class="sort-icon">
                          <font-awesome-icon
                            size="sm"
                            icon="caret-up"
                            :class="[
                              filterSKU.SortColumnId == 3 &&
                              filterSKU.SortType == false
                                ? 'opacity-2'
                                : '',
                              'color-primary  ml-2',
                            ]"
                          />
                          <font-awesome-icon
                            size="sm"
                            icon="caret-down"
                            :class="[
                              filterSKU.SortColumnId == 3 &&
                              filterSKU.SortType == true
                                ? 'opacity-2'
                                : '',
                              'color-primary ml-2',
                            ]"
                          />
                        </span>
                      </div>
                    </th>
                    <th>
                      <div
                        class="content-center cursor-pointer"
                        @click="sortSKUColumn(4)"
                      >
                        Day Left
                        <span class="sort-icon">
                          <font-awesome-icon
                            size="sm"
                            icon="caret-up"
                            :class="[
                              filterSKU.SortColumnId == 4 &&
                              filterSKU.SortType == false
                                ? 'opacity-2'
                                : '',
                              'color-primary  ml-2',
                            ]"
                          />
                          <font-awesome-icon
                            size="sm"
                            icon="caret-down"
                            :class="[
                              filterSKU.SortColumnId == 4 &&
                              filterSKU.SortType == true
                                ? 'opacity-2'
                                : '',
                              'color-primary ml-2',
                            ]"
                          />
                        </span>
                      </div>
                    </th>
                    <th>
                      <div
                        class="content-center cursor-pointer"
                        @click="sortSKUColumn(5)"
                      >
                        Daily Avg Sold (60D)
                        <span class="sort-icon">
                          <font-awesome-icon
                            size="sm"
                            icon="caret-up"
                            :class="[
                              filterSKU.SortColumnId == 5 &&
                              filterSKU.SortType == false
                                ? 'opacity-2'
                                : '',
                              'color-primary  ml-2',
                            ]"
                          />
                          <font-awesome-icon
                            size="sm"
                            icon="caret-down"
                            :class="[
                              filterSKU.SortColumnId == 5 &&
                              filterSKU.SortType == true
                                ? 'opacity-2'
                                : '',
                              'color-primary ml-2',
                            ]"
                          />
                        </span>
                      </div>
                    </th>
                    <th>
                      <div
                        class="content-center cursor-pointer"
                        @click="sortSKUColumn(6)"
                      >
                        Day Left
                        <span class="sort-icon">
                          <font-awesome-icon
                            size="sm"
                            icon="caret-up"
                            :class="[
                              filterSKU.SortColumnId == 6 &&
                              filterSKU.SortType == false
                                ? 'opacity-2'
                                : '',
                              'color-primary  ml-2',
                            ]"
                          />
                          <font-awesome-icon
                            size="sm"
                            icon="caret-down"
                            :class="[
                              filterSKU.SortColumnId == 6 &&
                              filterSKU.SortType == true
                                ? 'opacity-2'
                                : '',
                              'color-primary ml-2',
                            ]"
                          />
                        </span>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody v-if="!isSKULoading">
                  <tr
                    v-for="skuRow of SKUData"
                    :key="'row-' + skuRow.product_id"
                  >
                    <td>
                      <div>{{ skuRow.product_name }}</div>
                    </td>
                    <td class="text-center">{{ skuRow.daily_sold_7 }}</td>
                    <td class="text-center">{{ skuRow.days_left_7 }}</td>
                    <td class="text-center">{{ skuRow.daily_sold_30 }}</td>
                    <td class="text-center">{{ skuRow.days_left_30 }}</td>
                    <td class="text-center">{{ skuRow.daily_sold_60 }}</td>
                    <td class="text-center">{{ skuRow.days_left_60 }}</td>
                    <!-- <td>{{ skuRow.product_name }}</td> -->
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="7">
                      <div class="text-center text-black my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong class="ml-2">Loading...</strong>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <Pagination
              class="mt-3"
              @handleChangeTake="takeSKU"
              @pagination="paginationSKU"
              :filter="filterSKU"
              :rows="rows2"
            />
          </b-tab>
        </b-tabs>
      </div>
    </div>
    <SideBarFilter
      ref="filter"
      :filter="customFilterRequest"
      :placeholder="'Search Name'"
      @clearFilter="clearFilter"
      @searchAll="filterActions"
      :hideStatusFilter="false"
      :hideSearchBar="true"
    >
      <template v-slot:filter-option>
        <div class="mb-3">
          <MultiSelect
            used="product"
            textFloat="Barcode / Product"
            :options="options.product"
            textField="productName"
            valueField="id"
            placeholder="Select Barcode / Product"
            v-model="customFilterRequest.ProductFilter"
            :value="customFilterRequest.ProductFilter"
          />
        </div>

        <CategoryFilter
          ref="refsCategoryFilter"
          :propsValue="customFilterRequest"
          :options="options"
          @select="selectAH"
        />

        <div class="mb-3">
          <MultiSelect
            used="branch"
            textFloat="Purchase Branch"
            :options="options.branches"
            textField="branchName"
            valueField="id"
            placeholder="Select Purchase Branch"
            v-model="customFilterRequest.BranchFilter"
            :value="customFilterRequest.BranchFilter"
          />
        </div>
        <div class="mb-3">
          <MultiSelect
            used="channel"
            textFloat="Channel"
            :options="options.channelTypes"
            textField="channelName"
            valueField="id"
            placeholder="Select Channel"
            v-model="customFilterRequest.ChannelFilter"
            :value="customFilterRequest.ChannelFilter"
          />
        </div>
      </template>
    </SideBarFilter>
  </div>
</template>

<script>
import CategoryFilter from "@/components/CategoryFilter";
export default {
  components: {
    CategoryFilter,
  },
  data() {
    return {
      tabIndex: 0,
      customFilterRequest: {
        ChannelFilter: this.$store.state.filter.channel,
        BranchFilter: this.$store.state.filter.branch,

        ProductFilter: this.$store.state.filter.product,
        // product id เอาไว้เทสนะ
        ah_1: [],
        ah_2: [],
        ah_3: [],
        ah_4: [],
      },
      filter: {
        search: "", // Irina(Rhodium) ชื่อเอาไว้เทส
        PageNumber: 1,
        page: 1,
        take: 10,
        RecordPerPage: 10,
        SortColumnId: 0,
        SortType: true,
      },
      rows: 0,
      rows2: 0,
      filterSKU: {
        search: "", // Irina(Rhodium) ชื่อเอาไว้เทส
        PageNumber: 1,
        page: 1,
        take: 5,
        RecordPerPage: 5,
        SortColumnId: 0,
        SortType: true,
      },
      rows: 0,
      fields: [
        { key: "image", stickyColumn: true, label: "image" },
        { key: "imge_url", label: "Product Name", thClass: "table-width" },

        { key: "name", label: "Available Stock" },
        { key: "ah_no", label: "Branch Name" },
        { key: "price", label: "Store Hub" },
      ],
      items: [
        {
          isActive: true,
          age: 40,
          first_name: "Dickerson",
          last_name: "Macdonald",
        },
        { isActive: false, age: 21, first_name: "Larsen", last_name: "Shaw" },
        { isActive: false, age: 89, first_name: "Geneva", last_name: "Wilson" },
        { isActive: true, age: 38, first_name: "Jami", last_name: "Carney" },
      ],
      data: [],
      SKUData: [],
      marketList: [],
      options: {
        branches: [],
        product: [],
        channelTypes: [],
        ah_1: [],
        ah_2: [],
        ah_3: [],
        ah_4: [],
      },
      isInventoryLoad: false,
      isSKULoading: false,
    };
  },
  created() {
    this.getData();
    this.getSKUData();
    this.getOptions();
  },
  methods: {
    handleTabs(v) {},
    handleSearch() {
      if (this.tabIndex == 1) {
        this.filterSKU.search = this.filter.search;
        this.filterSKU.page = 1;
        this.filterSKU.take = 5;
        this.getSKUData();
      } else {
        this.filter.page = 1;
        this.filter.take = 5;
        this.getData();
      }
    },
    async getData() {
      this.noData = false;
      this.isInventoryLoad = true;
      let payload = {
        ...this.filter,
        customFilterRequest: this.customFilterRequest,
      };
      payload.SearchProduct = this.filter.search;
      payload.PageNumber = this.filter.page;
      payload.RecordPerPage = this.filter.take;
      const res = await this.$report.post(`/DashBoard/inventory`, payload);
      this.isInventoryLoad = false;
      if (res.data.detail.totalRecord > 0) {
        this.marketList = res.data.detail.product_data[0].product_marketplace;

        this.rows = res.data.detail.totalRecord;
        this.data = res.data.detail.product_data;
      } else {
        this.rows = 0;
        this.noData = true;
        this.data = [];
      }
    },
    async getSKUData() {
      let payload = {
        ...this.filterSKU,
        customFilterRequest: this.customFilterRequest,
      };
      payload.SearchProduct = this.filterSKU.search;
      payload.PageNumber = this.filterSKU.page;
      payload.RecordPerPage = this.filterSKU.take;
      this.isSKULoading = true;
      const res = await this.$report.post(`/DashBoard/inventory/SKU`, payload);

      this.rows2 = res.data.detail.totalRecord;
      this.SKUData = res.data.detail.data;
      this.isSKULoading = false;
    },
    pagination(val) {
      this.filter.page = val;
      this.getData();
    },
    handleChangeTake() {
      this.filter.page = 1;
      this.getData();
    },
    paginationSKU(val) {
      this.filterSKU.page = val;
      this.getSKUData();
    },
    takeSKU() {
      this.filterSKU.page = 1;
      this.getSKUData();
    },
    sidebarFilter() {
      this.$refs.filter.show();
      this.$nextTick(() => {
        this.$refs.refsCategoryFilter.getAH();
      });
    },

    async getOptions() {
      const customFilter = await this.$report(`/RevenueReport/CustomFilter`);
      this.options.memberTiers = customFilter.data.memberTiers;
      this.options.channelTypes = customFilter.data.channelTypes;
      this.options.branches = customFilter.data.branches;
      this.options.ageGroups = customFilter.data.ageGroups;
      this.options.genderModels = customFilter.data.genderModels;
      this.options.product = customFilter.data.products.map((el) => {
        return { id: el.id, productName: `${el.barcode} / ${el.productName}` };
      });
      this.options.promotions = customFilter.data.promotions;
    },
    clearFilter() {
      if (this.tabIndex == 1) {
        this.filterSKU.page = 1;
        this.filterSKU.take = 5;
        this.customFilterRequest = {
          ChannelFilter: [],
          BranchFilter: [],
          ProductFilter: [],
          ah_1: [],
          ah_2: [],
          ah_3: [],
          ah_4: [],
        };
        this.$store.dispatch("clearFilter");
        this.getSKUData();
      } else {
        this.filter.page = 1;
        this.filter.take = 5;
        this.customFilterRequest = {
          ChannelFilter: [],
          BranchFilter: [],
          ProductFilter: [],
          ah_1: [],
          ah_2: [],
          ah_3: [],
          ah_4: [],
        };
        this.$store.dispatch("clearFilter");

        this.getData();
      }
      this.$refs.filter.hide();
    },
    filterActions() {
      this.getData();
      this.getSKUData();
    },
    selectAH(val) {
      this.customFilterRequest = val;
    },
    async sortColumn(index) {
      this.filter.page = 1;
      this.isInventoryLoad = true;
      if (index == this.filter.SortColumnId) {
        if (this.filter.SortType == false) {
          this.filter.SortColumnId = 0;
        }
        this.filter.SortType = !this.filter.SortType;
      } else {
        this.filter.SortType = true;

        this.filter.SortColumnId = index;
      }

      await this.getData();
      this.isInventoryLoad = false;
    },
    async sortSKUColumn(index) {
      this.filterSKU.page = 1;
      this.isSKULoading = true;
      if (index == this.filterSKU.SortColumnId) {
        if (this.filterSKU.SortType == false) {
          this.filterSKU.SortColumnId = 0;
        }
        this.filterSKU.SortType = !this.filterSKU.SortType;
      } else {
        this.filterSKU.SortType = true;

        this.filterSKU.SortColumnId = index;
      }

      await this.getSKUData();
    },
  },
};
</script>

<style lang="scss" scoped>
table {
  position: relative;
  width: 100%;
  border-collapse: separate;
  color: green;
  display: block;
  max-width: 100%;
  overflow: auto;
  max-height: 60vh;
  thead th {
    text-align: center;
    border-top: 1px solid whitesmoke;
    border-right: 1px solid whitesmoke;
    // border-bottom: 1px solid whitesmoke;
    min-width: 120px;
    // position: sticky;
    // top: 0;
  }
  td {
    color: black;
    border-top: 1px solid whitesmoke;
  }

  tbody td:not(:last-child) {
    border-right: 1px solid whitesmoke;
  }
}
.overflow-x-scroll {
  overflow: auto;
}
table#inventory td:nth-child(1),
table#inventory td:nth-child(2),
table#inventory td:nth-child(3) {
  position: sticky;
  background: white;
  z-index: 2;
}
table#inventory tr:nth-child(1) th {
  position: sticky;
  top: 0px;
  background: var(--theme-secondary-color);
  color: var(--font-color);
}
table#inventory tr:nth-child(2) th {
  position: sticky;
  top: 24px;
  background: var(--theme-secondary-color);
  color: var(--font-color);
}

table#inventory tr:first-child > th:nth-child(1),
table#inventory tr:first-child > th:nth-child(2),
table#inventory tr:first-child > th:nth-child(3) {
  position: sticky;
  background: var(--theme-secondary-color);
  color: var(--font-color);
  width: 140px;
  z-index: 3;
  top: 0;
}
table#inventory td:nth-child(1),
table#inventory tr:first-child > th:nth-child(1) {
  left: 0px;
}
table#inventory td:nth-child(2),
table#inventory tr:first-child > th:nth-child(2) {
  left: 120px;
}
table#inventory td:nth-child(3),
table#inventory tr:first-child > th:nth-child(3) {
  left: 323px;
}
.image-box {
  width: 100%;
  max-width: 50px;
  height: auto;
  aspect-ratio: 1;
}
.container-table {
  width: 100%;
  overflow: scroll;
}
.sort-icon {
  display: inline-flex;
  flex-direction: column;
  // margin-left: auto;
  cursor: pointer;
}
.opacity-2 {
  opacity: 0.2;
}
table#sku-table tbody td {
  padding: 10px;
}
</style>
